<template>
    <div class="rootPayment">
        <h2>Правила оплаты </h2>
        <p>К оплате принимаются платежные карты: VISA Inc, MasterCard WorldWide, НСПК МИР.</p>
        <p>Для оплаты путевки банковской картой при оформлении заказа выберите способ оплаты на авторизационной странице банка: банковской картой</p>
        <p>При оплате заказа банковской картой, обработка платежа происходит на авторизационной странице банка, где Вам необходимо ввести данные Вашей банковской карты:</p> 
        <ol>
            <li>номер карты</li>
            <li>срок действия карты (указан на лицевой стороне карты)</li>
            <li>имя держателя карты (по требованию; латинскими буквами, точно также как указано на карте)</li>
            <li>CVC2/CVV2 код</li>
        </ol>
        <img src="@/assets/images/card.png" alt="card">
        <p>Если Ваша карта подключена к услуге 3D-Secure, Вы будете автоматически переадресованы на страницу банка, выпустившего карту, для прохождения процедуры аутентификации. Информацию о правилах и методах дополнительной идентификации уточняйте в Банке, выдавшем Вам банковскую карту.</p>
        <p>Безопасность обработки интернет-платежей через платежный шлюз банка гарантирована международным сертификатом безопасности PCI DSS. Передача информации происходит с применением технологии шифрования SSL. Эта информация недоступна посторонним лицам.</p>
        <h3>Советы и рекомендации по необходимым мерам безопасности проведения платежей с использованием банковской карты:</h3>
        <ol>
            <li><b>берегите свои пластиковые</b> карты так же, как бережете наличные деньги. Не забывайте их в машине, ресторане, магазине и т.д.</li>
            <li>никогда <b>не передавайте полный номер своей кредитной карты</b> по телефону каким-либо лицам или компаниям</li>
            <li>всегда имейте под рукой номер телефона для экстренной связи с банком, выпустившим вашу карту, и в случае ее утраты немедленно свяжитесь с банком</li>
            <li>вводите реквизиты карты только при совершении покупки. Никогда не указывайте их по каким-то другим причинам.</li>
        </ol>
        <h3>Отказ от путевки</h3>
        <p>Право потребителя на расторжение договора об оказании услуги регламентируется статьей 32 федерального закона «О защите прав потребителей»</p>
        <ul>
            <li>Потребитель вправе расторгнуть договор об оказании услуги в любое время, уплатив исполнителю часть цены пропорционально части оказанной услуги до получения извещения о расторжении указанного договора и возместив исполнителю расходы, произведенные им до этого момента в целях исполнения договора, если они не входят в указанную часть цены услуги;</li>
            <li>Возврат денежных средств при оплате банковской картой осуществляется на ту банковскую карту, с которой был сделан платеж. Срок возврата банком, после инициации возврата Продавцом, от 1 до 30 рабочих дней. </li>
        </ul>
        <p>С полными условиями возврата путевки Вы можете ознакомится в <a target="_blank" href="https://drive.google.com/file/d/1cWhQAyVmdy8nAHdWFCje6nxoMDVYm2F7/view?usp=sharing">Оферте</a> (п. 4).</p>
    </div>
</template>

<style lang="scss" scoped>
    .rootPayment {
        padding: 150px 0 0;
        max-width: 1080px;
        margin: 0 auto;
        @media screen and (max-width: 1300px) {
        max-width: 620px;
        }
        @media screen and (max-width: 680px) {
        max-width: 300px;
        }

        p, li {
            font-size: 20px;
        }
        h2 {
            font-size: 32px;
        }
        h3 {
            font-size: 26px;
        }
    }
</style>